<template>
    <b-container fluid>
        <h1 class="page-heading"> {{ $t('headings.add_new_order') }} </h1>
        <b-row>
            <b-col xl="7">
                <div class="order-configuration-container">
                    <div class="order-configuration-header">
                        <b-container>
                            <h3 class="section-heading"> {{ $t('headings.client_data') }} </h3>
                            <b-row>
                                <b-col xl="6">
                                    <div class="input-group">
                                        <input type="number" name="" v-model="phoneNumber" :placeholder="$t('placeholder.phone')">
                                    </div>
                                </b-col>
                                <b-col xl="6">
                                    <div class="order-phone-results">
                                        <div class="input-group select-input-group">
                                            <b-form-select v-model="shippingAddressesSelected">
                                              <option value="null" selected> {{ $t('placeholder.delivery_address') }} </option>
                                              <option v-for="(address, key) in shippingAddresses"
                                                  :key="key"
                                                  :value="address">
                                                  {{address.address}}
                                              </option>
                                            </b-form-select>
                                        </div>
                                        <button class="general-icon-button" v-b-modal.modal1>
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                    <div class="order-composition" v-if="reputationList && reputationList.length > 0">
                        <div class="reputation-list">
                            <i class="fas fa-exclamation-triangle"></i>
                            <p v-for="(order, key) in reputationList"  :key="key">
                                <span> <b> Comanda #{{order.id}} : </b> </span>  <span class="reputation-mentions"> {{order.delivery_obs}} </span>
                            </p>
                        </div>
                    </div>
                    <div class="order-composition">
                        <!-- <h3 class="section-heading"> Compozitie comanda </h3> -->
                        <div class="order-composition-header">
                            <b-row>
                                <b-col xl="1" lg="1">
                                    <div class="order-header-item">
                                    </div>
                                </b-col>
                                <b-col xl="1" lg="1">
                                    <div class="order-header-item">
                                        <p> {{ $t('tableHeadings.number') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="3" lg="3">
                                    <div class="order-header-item">
                                        <p> {{ $t('tableHeadings.name') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="2" lg="2">
                                    <div class="order-header-item">
                                        <p> {{ $t('tableHeadings.price') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="2" lg="2">
                                    <div class="order-header-item">
                                        <p> {{ $t('tableHeadings.quantity') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="2" lg="2">
                                    <div class="order-header-item price-align">
                                        <p> {{ $t('tableHeadings.subtotal') }} </p>
                                    </div>
                                </b-col>
                                <b-col xl="1" lg="1">
                                    <div class="order-header-item price-align">
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="order-composition-content" role="tablist">
                            <div class="order-collapse-item" v-for="(product, key) in orderProducts"  :key="key">
                                <b-row>
                                    <b-col xl="1" lg="1">
                                        <div class="order-see-mentions expand-button" role="tab" v-b-toggle="'product-accordion-' + key">
                                            <i class="fas fa-compress"></i>
                                        </div>
                                    </b-col>
                                    <b-col xl="1" lg="1">
                                        <div class="order-row-item">
                                            <p class="hidden-dsk"> {{ $t('tableHeadings.number') }} </p>
                                            <p> {{parseInt(key) + 1}} </p>
                                        </div>
                                    </b-col>
                                    <b-col xl="3" lg="3">
                                        <div class="order-row-item">
                                            <p class="hidden-dsk"> {{ $t('tableHeadings.name') }} </p>
                                            <p> {{product.name}} </p>
                                        </div>
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                        <div class="order-row-item">
                                            <p class="hidden-dsk"> Pret </p>
                                            <p> {{formatPrice(product.price)}} RON </p>
                                        </div>
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                        <div class="order-row-item order-row-qty">
                                            <button @click="product.qty > 1 ? product.qty-- : product.qty">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                            <input type="number" name="" v-model="product.qty">
                                            <button @click="product.qty++">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                        <div class="order-row-item price-align">
                                            <p> {{formatPrice(parseFloat(product.price) * parseInt(product.qty))}} RON </p>
                                        </div>
                                    </b-col>
                                    <b-col xl="1" lg="1">
                                        <div class="order-row-item text-right delete-element-icon">
                                            <span @click="orderProducts.splice(key,1)"><i class="fas fa-trash"></i></span>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-collapse :id="'product-accordion-' + key" accordion="my-accordion" role="tabpanel">
                                    <div class="order-item-collapse">
                                        <textarea name="name" placeholder="Mentiuni produs" rows="2" cols="80" v-model="product.mentions"></textarea>
                                    </div>
                                </b-collapse>
                            </div>
                            <div class="order-collapse-item" v-if="shippingAddressesSelected && shippingAddressesSelected.zone">
                                <b-row>
                                    <b-col xl="1" lg="1">
                                    </b-col>
                                    <b-col xl="1" lg="1">
                                    </b-col>
                                    <b-col xl="4" lg="4">
                                        <div class="order-row-item">
                                            <p class="hidden-dsk"> {{ $t('tableHeadings.name') }} </p>
                                            <p> TRANSPORT </p>
                                        </div>
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                    </b-col>
                                    <b-col xl="2" lg="2">
                                        <div class="order-row-item price-align">
                                            <p> {{formatPrice(shippingAddressesSelected.zone.transport || 0 )}} RON </p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="order-composition-footer">
                            <div class="order-footer-info">
                                <h4> {{ $t('placeholder.total') }} </h4>
                                <p> {{total}} RON </p>
                            </div>
                        </div>
                    </div>
                    <div class="order-finalize-details">
                        <h3 class="section-heading"> {{ $t('headings.finalize_order') }} </h3>
                        <div class="order-finalize-container">
                            <b-container>
                                <b-row>
                                    <b-col xl="6" lg="6" sm="6">
                                        <div class="order-mentions">
                                            <h4> {{ $t('headings.order_mentions') }} </h4>
                                            <textarea name="name" rows="4" cols="70" v-model="orderMentions"></textarea>
                                        </div>
                                    </b-col>
                                    <b-col xl="3" lg="3" sm="6">
                                        <div class="order-payment-type">
                                            <h4> {{ $t('headings.payment_method') }} </h4>
                                            <div class="input-radio-container">
                                                <div class="input-group-radio">
                                                    <input type="radio" id="payment-type1" name="payment-type" value="4" v-model="paymentType">
                                                    <label for="payment-type1"> {{ $t('placeholder.payment_card') }} </label>
                                                </div>
                                                <div class="input-group-radio">
                                                    <input type="radio" id="payment-type2" name="payment-type" value="1" v-model="paymentType">
                                                    <label for="payment-type2"> {{ $t('placeholder.payment_cash') }} </label>
                                                </div>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col xl="3" lg="3" sm="6">
                                        <div class="order-delivery-time">
                                            <h4> {{ $t('headings.delivery_time') }} </h4>
                                            <div class="input-group">
                                                <input type="number" v-model="deliveryTime" @change="setDeliveryTime()">
                                                <span> min </span>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="button-container">
                            <button type="button" class="general-action-button" @click="storeOrder()">{{ $t('placeholder.place_order') }}</button>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col xl="5">
                <categories-products />
            </b-col>
        </b-row>
        <b-modal id="modal1" hide-footer hide-header>
            <div class="modal-container new-address-modal">
                <div class="input-group">
                    <input type="text" id="input-ad-no-auto"                             :placeholder="$t('placeholder.add_new_address')"
                    @focus="initAutocomplete()"
                    autocomplete="new-address">
                </div>
                <div class="input-group">
                    <input type="text"
                    :placeholder="$t('placeholder.address_details')"
                     v-model="addressDetails"
                     autocomplete="no">
                </div>
                <div class="input-group select-input-group">
                    <b-form-select v-model="zoneSelected">
                      <option value="null" selected> {{$t('placeholder.delivery_area')}} </option>
                      <option v-for="(zone, key) in zones"
                          :key="key"
                          :value="zone">
                          {{zone.name}} | {{$t('placeholder.delivery')}} {{zone.transport}} RON | {{$t('placeholder.minimum')}} {{zone.minimum_order}} RON
                      </option>
                    </b-form-select>
                </div>
                <div class="modal-footer">
                    <button type="button" class="primary-button" @click="addAddressToSelect()">
                        <i class="far fa-save"></i>
                        {{$t('placeholder.save_address')}}
                    </button>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<script>

import CategoriesProducts from '@/components/PageComponents/CategoriesProducts'
import OrdersService from '@/api-services/orders.service.js'
import ZonesService from '@/api-services/zones.service.js'

export default {
  name: 'CreateOrder',
  components: {
    'categories-products': CategoriesProducts
  },
  data () {
    return {
      shippingAddressesSelected: null,
      addresInput: null,
      orderProducts: [],
      orderMentions: null,
      phoneNumber: null,
      addressDetails: null,
      deliveryTime: null,
      zones: [],
      zoneSelected: null,
      reputationList: null,
      paymentType: 1,
      shippingAddresses: [
        { value: null, text: 'Alege adresa' }
      ]
    }
  },
  watch: {
    phoneNumber: async function (newVal, oldVal) {
      if (newVal.length >= 10) {
        await OrdersService.getDeliveryAddressByPhoneNumber(newVal).then((response) => {
          this.shippingAddresses = response.data.data
          if (this.shippingAddresses.length > 0) {
            this.shippingAddressesSelected = this.shippingAddresses[0]
          }
        })
        await OrdersService.getBadReputationByPhoneNumber(newVal).then((response) => {
          if (response.data.status === 200) {
            this.reputationList = response.data.data
          }
        })
      } else {
        this.shippingAddresses = []
        this.shippingAddressesSelected = null
        this.reputationList = null
      }
    }
  },
  methods: {
    async getDeliveryTime () {
      await OrdersService.getDeliveryTime().then((response) => {
        this.deliveryTime = response.data.data.delivery_time
      })
    },
    async setDeliveryTime () {
      let data = new FormData()
      data.append('delivery_time', this.deliveryTime)
      await OrdersService.setDeliveryTime(data).then((response) => {
        this.deliveryTime = response.data.data.delivery_time
      })
    },
    getDeliveryZones () {
      ZonesService.getAll().then((response) => {
        if (response.data.status === 200) {
          this.zones = response.data.data
        }
      })
    },
    storeOrder () {
      if (this.shippingAddressesSelected && this.shippingAddressesSelected.zone) {
        if (parseInt(this.shippingAddressesSelected.zone.minimum_order) > parseInt(this.subtotal)) {
          let vm = this
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: this.$t('alert.negative.minimum_ammount') + ' ' + vm.shippingAddressesSelected.zone.minimum_order + ' RON'
          })
          return false
        }
      }
      this.$store.dispatch('showLoading')
      let data = {
        'shipping_address': this.shippingAddressesSelected,
        'phone': this.phoneNumber,
        'products': this.orderProducts,
        'mentions': this.orderMentions,
        'operator_delivery_time': this.deliveryTime,
        'payment-type': this.paymentType
      }
      let vm = this
      OrdersService.store(data).then((response) => {
        vm.$store.dispatch('hideLoading')
        if (response.data.status === 200) {
          vm.$swal.fire({
            icon: 'success',
            title: this.$t('alert.positive.order_success'),
            showConfirmButton: false,
            timer: 2500
          }).then((result) => {
            vm.$router.push('/orders')
          })
        }
      }).catch(function (error) {
        vm.$store.dispatch('hideLoading')
        if (error.response.data.errors) {
          let errors = error.response.data.errors
          let msg = ''
          for (let i in errors) {
            msg += errors[i][0] + '<br />'
          }
          vm.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: msg
          })
        }
      })
    },
    addAddressToSelect () {
      if (this.shippingAddresses) {
        let newAddr = this.shippingAddresses.find(addr => parseInt(addr.id) === 0)
        this.shippingAddresses.splice(this.shippingAddresses.lastIndexOf(newAddr), 1)
      } else {
        this.shippingAddresses = [ { value: null, text: 'Alege adresa' } ]
      }
      if (this.addresInput && this.zoneSelected) {
        this.addresInput.zone = window._.clone(this.zoneSelected)
        this.shippingAddresses.push(this.addresInput)
        this.shippingAddressesSelected = this.shippingAddresses[this.shippingAddresses.length - 1]
        this.$bvModal.hide('modal1')
      }
    },
    initAutocomplete () {
      var input = document.getElementById('input-ad-no-auto')
      var defaultBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(47.089071, 27.477792),
        new window.google.maps.LatLng(47.224709, 27.678979)
      )
      var options = {
        bounds: defaultBounds,
        componentRestrictions: { country: 'ro' },
        types: ['address'],
        strictBounds: true
      }

      var autocomplete = new window.google.maps.places.Autocomplete(input, options)
      let vm = this
      window.google.maps.event.addListener(autocomplete, 'place_changed', function (result) {
        vm.addresInput = {
          'id': 0,
          'value': 0,
          'text': autocomplete.getPlace().formatted_address,
          'address': autocomplete.getPlace().formatted_address,
          'addressDetails': vm.addressDetails,
          'place': autocomplete.getPlace(),
          'lat': autocomplete.getPlace().geometry.location.lat(),
          'lng': autocomplete.getPlace().geometry.location.lng()
        }
      })
    }
  },
  computed: {
    subtotal () {
      let subtotal = 0
      this.orderProducts.forEach(function (element) {
        subtotal += element.price * element.qty
      })
      return this.formatPrice(subtotal)
    },
    total () {
      let total = 0
      this.orderProducts.forEach(function (element) {
        total += element.price * element.qty
      })
      if (this.shippingAddressesSelected && this.shippingAddressesSelected.zone && this.shippingAddressesSelected.zone.transport) {
        total += parseInt(this.shippingAddressesSelected.zone.transport)
      }
      return this.formatPrice(total)
    }
  },
  async created () {
    window.Echo.channel('operator-delivery-time-update').listen('DeliveryTimeUpdate', (e) => {
      this.deliveryTime = e.message
    })
    this.getDeliveryTime()
    this.getDeliveryZones()
    this.$store.dispatch('hideLoading')
  }
}
</script>
